import React from 'react';
import MainImage from './MainImage';
import ReactPlayer from 'react-player';
import InstagramEmbed from 'react-instagram-embed';

const AuthorReference = ({ node }) => {
  if (node && node.author && node.author.name) {
    return <span>{node.author.name}</span>;
  }
  return <></>;
};

const serializers = {
  types: {
    authorReference: AuthorReference,
    mainImage: ({ node }) => <MainImage mainImage={node}/>,
    videoEmbed: ({ node }) => <ReactPlayer className="mt-6 mb-6" url={node.url} controls/>,
    instagram: ({ node }) => {
      if (!node.url) return null;
      return <InstagramEmbed url={node.url} className="container mx-auto mt-6 mb-6"/>;
    },
  },
  listItem: ({ node: { listItem }, children }) => <li
    className={`list-outside mx-8 ${listItem === 'bullet' ? 'list-disc' : 'list-decimal'}`}>{children}</li>,
};

export default serializers;
